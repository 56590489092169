import React from "react";

export const About = (props) => {
  return (
    <div id="about" className="text-center">
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            <div className="about-text">
              <h2>About Us</h2>
              <h1>
                  {props.data ? props.data.title : "Loading"}
                  <span></span>
              </h1>
              <p>{props.data ? props.data.paragraph : "loading..."}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
