import React from "react";

export const Mission = (props) => {
  return (
    <div id="mission" className="text-center">
      <div className="container">
       <h2>Our Philanthropic Mission</h2>
        <div className="section-title">
          <p>
            {props.data ? props.data.paragraph : "loading..."}
          </p>
        </div>
      </div>
    </div>
  );
};
