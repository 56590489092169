import React from "react";

export const Portfolio = (props) => {
  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="portfolio-text">
          <h2>Portfolio</h2>
          <div className="section-title">
          <p>
            {props.data ? props.data.paragraph : "loading..."}
          </p>
          </div> 
        </div>
        <div className="section-title">
          <h1>
            {props.data ? props.data.web3title : "Loading"}
            <span></span>
          </h1>
        </div>
        
        <div id="row">
          {props.data
            ? props.data.web3icons.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-2 col-sm-6 team">
                  <div className="thumbnail">
                    {" "}
                    <a href={d.link} target="_blank" rel="noreferrer">
                      <img src={d.image} alt={d.name} className="investment-img" />
                    </a>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
       
      </div>
      <div>
      <div className="section-title investment">
          <h1>
            {props.data ? props.data.web2title : "Loading"}
            <span></span>
          </h1>
      </div>
        <div id="row">
          <div className="col-md-2"></div>
          {props.data
            ? props.data.web2icons.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4 col-sm-6">
                  <div className="thumbnail">
                    {" "}
                    <a href={d.link} target="_blank" rel="noreferrer"><img src={d.image} alt={d.name} className="investment-img" /></a>
                    
                  </div>
                </div>
              ))
            : "loading"}
          <div className="col-md-2"></div>  
        </div>
      </div>
    </div>
  );
};
