import React from "react";
import  privacyPolicyPdf  from "../data/privacypolicy.pdf";

export const Footer = (props) => {
  return (
    <div>
      <div id="footer-links">
        <div className="container">
          <div className="footer-section"></div>
          <div className="links">
            <div className="col-md-2">
              <a href={privacyPolicyPdf} target="_blank" rel="noreferrer">Privacy Policy</a>
            
            </div>
            <div className="col-md-2">
              <a href="mailto:info@pillar-ag.com">Contact</a>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">

        <div className="container text-center">
          <p>
            &copy; 2023 PPAG
          </p>
        </div>
      </div>
    </div>
  );
};
